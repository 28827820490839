
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import "twind.config"

import withTwindApp from "@twind/next/app"
import {
  AppProps,
  AuthenticationError,
  AuthorizationError,
  ErrorBoundary,
  ErrorComponent,
  ErrorFallbackProps,
  NextWebVitalsMetric,
  useQueryErrorResetBoundary,
} from "blitz"
import { identity } from "fp-ts/function"
import React from "react"
import twindConfig from "twind.config"

// ts-prune-ignore-next
export function reportWebVitals({ id, label, name, value }: NextWebVitalsMetric) {
  if (window.gtag) {
    window.gtag("event", name, {
      event_category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
      // values must be integers
      event_label: id,
      // id unique to current page load
      non_interaction: true,
      value: Math.round(name === "CLS" ? value * 1000 : value), // avoids affecting bounce rate.
    })
  }
}

function RootErrorFallback({ error }: ErrorFallbackProps) {
  if (error instanceof AuthenticationError) {
    return <></>
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    return (
      <ErrorComponent statusCode={error.statusCode ?? 400} title={error.message ?? error.name} />
    )
  }
}

function App({ Component, pageProps }: AppProps) {
  const getLayout = Component.getLayout ?? identity

  return (
    <ErrorBoundary
      FallbackComponent={RootErrorFallback}
      onReset={useQueryErrorResetBoundary().reset}
    >
      {getLayout(<Component {...pageProps} />)}
    </ErrorBoundary>
  )
}

// ts-prune-ignore-next
const __Page_Next_Translate__ = withTwindApp(twindConfig, App)


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  