import { apply, Configuration, css } from "twind/css"

const configuration: Configuration = {
  plugins: {
    blogLink: `text-blue(900 hover:600)`,
    btn: `
      px-4 py-2
      font-semibold
      shadow-md
      not-disabled:cursor-pointer
      select-none
    `,
    "btn-aux":
      "btn border-2 border-blue-500 text-blue-500 not-disabled:hover:(border-blue-600 text-blue-600 bg-blue-50)",
    "btn-delete": `btn border-2 border-red-500 text-red-500 not-disabled:hover:(border-red-600 text-red-600 bg-red-50)`,
    "btn-disabled": "bg-gray-400",
    "btn-new":
      "btn border-2 border-green-500 text-green-500 not-disabled:hover:(border-green-600 text-green-600 bg-green-50) px-4 py-1",
    "btn-publish":
      "btn border-2 border-yellow-500 text-yellow-500 not-disabled:hover:(border-yellow-600 text-yellow-600 bg-yellow-50)",
    "btn-ring": "btn border-2 text-gray-300 border-gray-200",
    "btn-save":
      "btn border-2 border(gray-400 not-disabled:blue(500 hover:600)) bg(blue(500 hover:not-disabled:600)) text-white",
    "input-container": "border px-3 py-1",
    "input-label": "text-sm font-semibold select-none",
    "ram-lg": css`
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    `,
    "ram-sm": css`
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    `,
    "ram-xs": css`
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    `,
    theme: {
      extend: {
        screens: {
          standalone: { raw: "(display-mode:standalone)" },
        },
      },
    },
  },
  preflight: (preflight) => css`
    ${preflight}
    :root {
      ${apply`text-gray-900`};
    }

    button:disabled {
      cursor: default;
    }

    .structured-text a,
    .structured-text-link {
      ${apply`text-blue-500`};
    }
  `,
}

declare module "twind" {
  interface Plugins {
    btn: "" | "delete"
  }
}

export default configuration
